import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import mq from '../../styles/mediaQueries'

import SubServicesPageTemplate from '../../templates/sub-services-page'

import Seo from '../../components/seo'
import Layout from '../../components/Layout'
import RailsSVG from '../../assets/logo/rails-logo.svg'

import {
  Headline2,
  Headline3,
  Body1,
  Body2,
  Subheading2,
  Subheading1,
} from '../../components/Typography'

const RailsLogo = props => {
  const StyledRails = styled(RailsSVG)`
    width: 48px;
    height: 48px;
    display: block;
    ${mq({
      marginBottom: ['24px', '36px', '36px', '36px', '36px'],
    })};

    path {
      fill: var(--terminal-0);
    }
  `

  return <StyledRails {...props} />
}

const Wrapper = styled.div`
  grid-column: 1/-1;
`

const Header = styled.div`
  ${Headline2};
  color: ${props => (props.grey ? 'var(--terminal-200)' : 'var(--terminal-0)')};
`

const SubHeader = styled.div`
  ${Subheading2};
  color: var(--terminal-0);
  ${mq({
    margin: [
      '130px 0 48px 0',
      '130px 0 48px 0',
      '240px  0 120px 0',
      '240px  0 120px 0',
      '240px  0 120px 0',
    ],
    gridColumn: [
      '1 / span 4',
      '1 / span 8',
      '1 / span 8',
      '1 / span 8',
      '1 / span 8',
    ],
  })};
`

const LeftColumn = styled.div`
  ${Headline3};
  color: var(--terminal-0);
  ${mq({
    gridColumn: [
      '1 / span 4',
      '1 / span 8',
      '1 / span 4',
      '1 / span 4',
      '1 / span 4',
    ],
    margin: ['48px 0 8px 0', '48px 0 8px 0', 'unset', 'unset', 'unset'],
  })};
`

const RightColumn = styled.div`
  ${Body2};
  ${mq({
    gridColumn: [
      '1 / span 4',
      '1 / span 8',
      '5 / span 8',
      '5 / span 8',
      '5 / span 8',
    ],
    marginBottom: ['24px', '24px', 'unset', 'unset', 'unset'],
    color: [
      'var(--terminal-100)',
      'var(--terminal-100)',
      'var(--terminal-0)',
      'var(--terminal-0)',
      'var(--terminal-0)',
    ],
  })};
`

const HorizontalLine = styled.hr`
  border: 1px solid var(--terminal-300);
  margin: 60px 0;
  grid-column: 1/-1;
  ${mq({
    display: ['none', 'none', 'block', 'block', 'block'],
  })};
`

const CenteredSubHeader = styled.div`
  ${Subheading1}
  color: var(--terminal-0);
  ${mq({
    margin: [
      '204px 0 120px 0',
      '204px 0 120px 0',
      '220px  0 120px 0',
      '220px  0 120px 0',
      '220px  0 120px 0',
    ],
    gridColumnStart: ['1', '1', '1', '3', '3'],
    gridColumnEnd: ['5', '9', '13', '11', '11'],
  })};

  a {
    color: var(--screen-300);
    text-decoration: none;
  }
`

const Spacer = styled.div`
  height: 200px;
`

const OrangeGatsbyLink = styled(Link)`
  ${Body1};
  color: var(--firewall-500);
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 36px;
  display: block;
`

const OrangeLink = styled.a`
  ${Body1};
  color: var(--firewall-500);
  text-decoration: none;
  font-weight: bold;
  ${mq({
    margin: [
      '12px 0 0 0',
      '12px 0 0 0',
      '60px 36px 0 0',
      '60px 36px 0 0',
      '60px 36px 0 0',
    ],
    display: ['block', 'block', 'inline-block', 'inline-block', 'inline-block'],
  })};
`

function Solidus() {
  const pageTitle = 'Ruby on Rails'

  return (
    <SubServicesPageTemplate title={pageTitle} copyrightYear={'2022'}>
      <Seo
        title={pageTitle}
        description="It's a mature platform with great third-party support, and it's constantly receiving new features, security improvements, and performance boosts."
        keywords={[
          `Ruby on Rails`,
          `Rails`,
          `React`,
          `Spree`,
          `Solidus`,
          `eCommerce`,
        ]}
      />
      <Layout>
        <Wrapper>
          <Spacer />
          <OrangeGatsbyLink to="/services">← Back to services</OrangeGatsbyLink>
          <RailsLogo />
          <Header>Ruby on Rails</Header>
          <Header grey={true}>
            Optimized for programmer happiness, used to build the biggest
            software online.
          </Header>
        </Wrapper>
        <SubHeader>
          Employ a scalable platform designed for productivity.
        </SubHeader>
        <LeftColumn>Trusted technology</LeftColumn>
        <RightColumn>
          We use Ruby on Rails for most projects — for good reason. A mature
          platform trusted by companies like Shopify and Github, Ruby on Rails
          has great third-party support and is constantly receiving new
          features, security improvements, and performance boosts.
        </RightColumn>
        <HorizontalLine />
        <LeftColumn>Best for new applications</LeftColumn>
        <RightColumn>
          Rails is opinionated, which makes it great for starting new projects.
          It comes with batteries-included, ready for developers to quickly
          start building out new applications without fussing over setup and
          configuration.
        </RightColumn>
        <HorizontalLine />
        <LeftColumn>Rich Ruby developer community</LeftColumn>
        <RightColumn>
          Additionally, Ruby on Rails has a great community of Rubyists out
          there if you're looking to hire internal developers.
        </RightColumn>
        <Wrapper>
          <OrangeLink href="https://rubyonrails.org/">
            Visit the Ruby on Rails website →
          </OrangeLink>{' '}
        </Wrapper>
        <CenteredSubHeader>
          What we’re able to build is only restricted by your imagination. From
          custom shopping experiences to enterprise-level integrations and admin
          tools-we've seen and done it all. See some examples of{' '}
          <Link to="/work">our work</Link> and read{' '}
          <Link to="/blog">our blog</Link> for more info. When you're ready to
          get building, <Link to="/contact">let's talk</Link>.
        </CenteredSubHeader>
      </Layout>
    </SubServicesPageTemplate>
  )
}

export default Solidus
